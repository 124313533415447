import { graphql, navigate } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import GlobalStyles from '../styles/globalStyles';
import HomePage from '../templates/homePageTemplate/homePageTemplate';
import { addTrailingSlash } from '../utils';
import LogoOG from '../images/logo-og.jpg';

const RootIndex = ({ location }) => {
  React.useEffect(() => {
    if (location?.href !== location?.href?.toLowerCase()) {
      window.location.href = location?.href?.toLowerCase();
    }
    if (location?.pathname !== '/') {
      navigate('/404');
    }
  }, []);

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Parkland | Chevron :: Home</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <meta
          name="description"
          content="Parkland Corporation is the exclusive marketer of Chevron fuel in Canada."
        ></meta>
        <meta
          name="keywords"
          content="Parkland, Chevron, Fuel, Canada, Retail, Commercial, Gas Station, Cardlock"
        ></meta>
        <meta name="generator" content="concrete5 - 5.6.3.2"></meta>
        <meta property="og:type" content="website"></meta>
        <meta property="og:title" content="Parkland | Chevron"></meta>
        <meta
          property="og:description"
          content="Retail and commercial fuel services"
        ></meta>
        <meta property="og:image" content={LogoOG}></meta>
        <meta
          property="og:url"
          content={addTrailingSlash(process.env.GATSBY_DOMAIN_URL)}
        ></meta>
        <meta name="twitter:title" content="Parkland | Chevron"></meta>
        <meta
          name="twitter:description"
          content="Retail and commercial fuel services"
        ></meta>
        <meta name="twitter:image" content={LogoOG}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
      </Helmet>
      <HomePage />
    </>
  );
};

export default RootIndex;

export const pageQuery = graphql`
  fragment PageContent on ContentfulContent {
    id
    title
    type
    header
    subText
    elementId
    contentDetails {
      raw
    }
  }
  fragment ContentList on ContentfulContentList {
    id
    title
    type
    header
    subtext
    elementId
    list {
      title
      type
      header
      subText
      elementId
      contentDetails {
        raw
      }
    }
    listDetails {
      raw
    }
  }
  fragment ContentFaqs on ContentfulFaqs {
    id
    title
    type
    description
    qna {
      title
      text {
        raw
      }
    }
  }
  fragment ContentfulListImages on ContentfulListOfImageWithPath {
    id
    type
    title
    listOfImagesWithPath {
      title
      type
      richText {
        raw
      }
      image {
        file {
          url
        }
      }
    }
  }
  fragment TextWithImage on ContentfulTextWithImage {
    id
    title
    type
    image {
      title
      file {
        url
      }
    }
    text {
      text
    }
  }
  fragment FAQContent on ContentfulFaqs {
    id
    title
    type
    header
    description
    elementId
    qna {
      title
      type
      text {
        raw
      }
      buttons {
        raw
      }
    }
  }
  fragment MultimediaText on ContentfulMultimediaText {
    title
    type
    multimediaText: text {
      raw
    }
  }
  fragment ImagesWithPathList on ContentfulListOfImageWithPath {
    id
    title
    type
    listOfImagesWithPath {
      title
      type
      path
      richText {
        raw
      }
      image {
        file {
          fileName
          url
        }
      }
    }
  }
  fragment ImageWithPath on ContentfulImageWithPath {
    id
    title
    type
    path
    richText {
      raw
    }
    image {
      file {
        url
      }
    }
  }
  fragment ImageContainer on ContentfulImageContainer {
    id
    title
    type
    image {
      file {
        url
      }
    }
    assets {
      title
      description
      file {
        fileName
        url
      }
    }
    textWithPaths {
      ... on ContentfulTextWithPath {
        id
        path
        text
      }
    }
    headerWithText {
      headerText
      text {
        text
      }
    }
  }
`;
