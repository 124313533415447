import styled from 'styled-components';

export const AnchorStyle = styled.a`
  display: inline;
  position: fixed;
  z-index: 999;
  bottom: 0;
  right: 0;
  padding: 0 15px 10px 0;
  text-align: center;
  transition: padding 0.15s ease-out;
  @media (max-width: 767px) {
    padding: 11px 15px 9px 15px;
  }
  :hover {
    padding: 0 15px 17px 0;
  }
`;

export const ArrowStyle = styled.img`
  width: 30px;
  height: auto;
  border: 0;
  vertical-align: top;
`;
