import React from 'react';
import * as Styled from './bottomArrowStyle';
import Arrow from '../../images/arrow-blue-backtotop.png';

const BottomArrow = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  React.useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const topToScroll = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };

  return (
    <React.Fragment>
      {scrollPosition > 0 && (
        <Styled.AnchorStyle onClick={() => topToScroll()}>
          <Styled.ArrowStyle src={Arrow} alt="BackToTop Arrow" title='Back to top'/>
        </Styled.AnchorStyle>
      )}
    </React.Fragment>
  );
};

export default BottomArrow;
