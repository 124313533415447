import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import constants from '../../utils/utils';
import * as Styled from './homePageTemplateStyle';
import { Row, Col } from 'react-bootstrap';
import DefaultHeaderMenu from '../../components/defaultHeaderMenu/defaultHeaderMenu';
import BootstrapNavbar from '../../components/navigationBar/mainMenu/navigationBar';
import HomeBottom from '../../components/homeBottom/homeBottom';
import Footer from '../../components/footer/footer';
import HomeCarbonBanner from '../../components/homeCarbonBanner/homeCarbonBanner';
// import HomeGreyBackground from '../../components/homeGreyBackground/homeGreyBackground';
import AccountTemplate from '../../components/navigationBar/accountTemplate/accountTemplate';
import BottomArrow from '../../components/bottomArrow/bottomArrow';

const templateAccount = [
  {
    anchorBGColor: '#083a81d9',
    anchorHoverColor: '#0397d6',
    isLeft: true,
  },
  {
    anchorBGColor: '#004b2ed9',
    anchorHoverColor: '#50C878',
  },
];
const HomePage = () => {
  const pageData = useStaticQuery(graphql`
    query myHomePageQuery {
      allContentfulChevron(
        filter: {
          identifier: { type: { eq: "homePage" } }
          node_locale: { eq: "en-US" }
        }
      ) {
        edges {
          node {
            title
            slug
            node_locale
            sections {
              ... on ContentfulHomeContainers {
                id
                title
                type
                homecontainers {
                  type
                  title
                  label
                  textWithLink1 {
                    ... on ContentfulTextWithPath {
                      id
                      path
                      type
                      text
                    }
                  }
                  backgroundImages {
                    file {
                      url
                      fileName
                    }
                  }
                  textWithLink2 {
                    ... on ContentfulTextWithPath {
                      id
                      type
                      text
                      path
                    }
                  }
                  textWithLink3 {
                    ... on ContentfulTextWithPath {
                      id
                      path
                      type
                      text
                    }
                  }
                }
              }
              ... on ContentfulListOfImageWithPath {
                id
                title
                type
                listOfImagesWithPath {
                  path
                  title
                  type
                  richText {
                    raw
                  }
                  image {
                    title
                    file {
                      fileName
                      url
                    }
                  }
                }
              }
              ... on ContentfulImageWithPath {
								title
                type
                path
                image {
                    title
                    file {
                      fileName
                      url
                    }
                  }
              }
            }
          }
        }
      }
    }
  `);
  const sections = pageData.allContentfulChevron.edges[0].node.sections;
  const accountTemplateData = sections?.filter(
    (section) => section.type === constants.HOME.HOME_CONTAINERS
  )[0];
  const HomeBottomData = sections?.filter(
    (section) => section.type === constants.HOME.HOME_LOCATIONS
  )[0];
  const HomeCarbonData = sections?.filter(
    (section) => section.type === constants.HOME.HOME_CARBON_OFFSET
  )[0];

  return (
    <Styled.HomePageContainer>
      <BottomArrow />
      <DefaultHeaderMenu />
      <BootstrapNavbar />
      <Styled.ImageContainer className="p-0">
        <Row className="g-0">
          <Col xs={12} md className="px-0">
            <AccountTemplate
              {...accountTemplateData?.homecontainers.filter(
                (section) =>
                  section?.type === constants?.HOME?.HOME_RETAIL_SECTION
              )[0]}
              {...templateAccount[0]}
            />
          </Col>
          <Col xs={12} md className="px-0">
            <AccountTemplate
              {...accountTemplateData?.homecontainers.filter(
                (section) =>
                  section.type === constants?.HOME?.HOME_COMMERCIAL_SECTION
              )[0]}
              {...templateAccount[1]}
            />
          </Col>
        </Row>
      </Styled.ImageContainer>
      <HomeBottom sectionData={HomeBottomData} />
      <HomeCarbonBanner sectionData={HomeCarbonData} />
      <Footer />
    </Styled.HomePageContainer>
  );
};

export default HomePage;
