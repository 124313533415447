import React from 'react'
import * as Styled from './homeCarbonBannerStyle';
import constants from '../../utils/utils';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import isExternalUrl, {
  isExternalUrlhref,
  addTrailingSlash,
} from '../../utils';

const HomeCarbonBanner = ({ sectionData }) => {

  return (
    <div>
      <Styled.AnchorStyle
        href={
          isExternalUrlhref(sectionData?.path)
            ? sectionData?.path
            : addTrailingSlash(
                process.env.GATSBY_DOMAIN_URL + sectionData?.path
              )
        }
        target='_blank'
      >
        <Styled.CarbonOffsetBanner
          src={sectionData?.image?.file?.url}
          alt={sectionData?.image?.file?.fileName}
        />
      </Styled.AnchorStyle>
    </div>
  )
}

export default HomeCarbonBanner;